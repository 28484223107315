<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <bullet
        :title="'Update Community Activities'"
        :canBack="true"
        colorIcon="#052633"
      />
      <div class="mt-4 d-flex align-center justify-center full_w">
        <div class="_temp_list_medium">
          <div v-if="!loading && dataSet">
            <h4>Nama Activities</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama activities disini"
              v-model="dataSet.nama"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
          <div v-if="loading">
            <v-skeleton-loader
              class="rounded-lg"
              width="90px"
              height="20px"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded-lg mt-1"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div class="mt-3" v-if="!loading && dataSet">
            <h4>Tanggal Pelaksanaan</h4>
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dataSet.tanggal_pelaksanaan"
                  prepend-inner-icon="mdi-calendar-range"
                  readonly
                  placeholder="Tanggal transaksi"
                  hide-details
                  dense
                  v-on="on"
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataSet.tanggal_pelaksanaan"
                @input="closeDateMenu(dataSet.tanggal_pelaksanaan)"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="mt-3" v-if="loading">
            <v-skeleton-loader
              class="rounded-lg"
              width="90px"
              height="20px"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded-lg mt-1"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div class="mt-3" v-if="!loading && dataSet">
            <h4>Metode</h4>
            <v-radio-group
              v-model="dataSet.metode"
              hide-details
              class="ma-0"
              dense
              row
            >
              <v-radio label="Online" color="#052633" value="online"></v-radio>
              <v-radio
                label="Offline"
                color="#052633"
                value="offline"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="mt-3" v-if="loading">
            <v-skeleton-loader
              class="rounded-lg"
              width="90px"
              height="20px"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded-lg mt-1"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div class="mt-3" v-if="!loading && dataSet">
            <h4>Tujuan</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama activities disini"
              v-model="dataSet.tujuan"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="mt-3" v-if="loading">
            <v-skeleton-loader
              class="rounded-lg"
              width="90px"
              height="20px"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded-lg mt-1"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div class="mt-3" v-if="!loading && dataSet">
            <h4>Lokasi/Link</h4>
            <v-textarea
              rows="3"
              auto-grow
              class="rounded-lg"
              outlined
              placeholder="Silakan isi nama activities disini"
              v-model="dataSet.lokasi"
              color="#0D47A1"
              dense
              hide-details
            ></v-textarea>
          </div>
          <div class="mt-3" v-if="loading">
            <v-skeleton-loader
              class="rounded-lg"
              width="90px"
              height="20px"
              type="image"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded-lg mt-1"
              width="100%"
              height="40px"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div class="mt-3">
            <h4>Pilih Instansi Partner</h4>
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg mt-2"
              placeholder="Search for Instansi"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchDataSearch"
            ></v-text-field>
            <div
              v-if="!loading && dataSet"
              class="mt-3 d-flex align-center flex-wrap"
            >
              <v-card
                class="mr-2"
                min-width="100px"
                flat
                color="#052633"
                v-for="(item, n) in field.partner"
                :key="n"
                dark
                rounded="xl"
                style="flex-grow: 1"
              >
                <div class="d-flex align-center justify-center px-3">
                  {{ item.nama_instansi?item.nama_instansi:item.instansi.nama_instansi }}
                  <v-btn small class="ml-2" icon depressed @click="remove(n)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </div>
            <v-data-table
              v-if="dataPartner && !loading"
              v-model="field.partner"
              :headers="headers"
              :items="dataPartner"
              item-key="instansi_partner_id"
              show-select
              class="elevation-0 mt-3"
              hide-default-footer
              :single-select="true"
            >
            <template #item="{ item }">
              <tr>
                <td>
                  <v-checkbox 
                    v-if="field.partner.find(instansi => instansi.instansi_partner_id === item.instansi_partner_id)"
                    disabled
                    class="pa-0 ma-0"
                    :ripple="false"
                    input-value="true"
                    hide-details>
                  </v-checkbox>
                  <v-checkbox 
                    v-else
                    class="pa-0 ma-0"
                    :ripple="false"
                    hide-details
                    @click="addData(item)">
                  </v-checkbox>
                  </td>
                <td>{{item.nama_instansi}}</td>
                <td>{{item.email}}</td>
                <td>{{item.alamat}}</td>
              </tr>
            </template>
            </v-data-table>
            <v-skeleton-loader
              class="pa-2 elevation-0"
              type="table-tbody"
              v-if="loading"
            ></v-skeleton-loader>
          </div>
          <div class="d-flex align-center justify-end mt-4">
            <v-btn
              :disabled="loadingSave ? true:false"
              depressed
              class="text-capitalize bg_default"
              @click="updateData"
              :dark="!loadingSave ? true:false"
            >
              <h4 v-if="!loadingSave">Simpan</h4>
              <h4 v-if="loadingSave">Loading</h4>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="inventory.path != ''?resetInstansi(true):field.partner.splice(field.idx_deleted, 1);"
        @close="d_type == 'add_function'?resetInstansi(false):d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
import DialogSmall from '../Dialog/dialogSmall.vue';
export default {
  components: { bullet, NavbarProfile, DialogSmall },
  name: "updateCommunityActivites",
  data() {
    return {
      loading: true,
      loadingSave: false,
      menuDate: false,
      find: "",
      dataSet: null,
      dataPartner: [],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Community Activities",
          disabled: false,
          href: "/admin/cd/community-activities",
        },
        {
          text: "Update Community Activities",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Instansi Partner", value: "nama_instansi" },
        { text: "Email", value: "email" },
        { text: "Alamat", value: "alamat" },
      ],
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      field: {
        partner: [],
        idx_deleted: null,
      },
      isRunning:false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    closeDateMenu(item) {
      this.menuDate = false;
      this.dataSet.tanggal_pelaksanaan == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },
   async resetInstansi(isRunning){
     this.field.partner = [];
     this.dataPartner = [];
     this.d_small = false
     if(!this.isRunning){
        this.isRunning = isRunning;
        this.fetchData();
      }
    },
    async fetchDataSearch(){
      this.field.partner = [];
      this.dataPartner = [];
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/community-development/event/list?filter[id]=${this.$route.params.idActivities}&include=instansi_partner`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.dataSet = data.data.data[0];
            if(this.field.partner.length == 0){
              this.dataSet.instansi_partner.forEach(item => {
                let instansiEvent = {
                  id:item.id,
                  community_development_event_id:item.community_development_event_id,
                  klien_id:item.klien_id,
                  instansi_partner_id:item.instansi_partner_id,
                  nama_instansi:item.instansi.nama_instansi,
                  email:item.instansi.email,
                  alamat:item.instansi.alamat,
                };
                this.field.partner.push(instansiEvent);
              });
            }
          this.fetchDataPartner();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
          this.isRunning = false;
        });
    },
    fetchDataPartner() {
      this.loading = true;
      let data = {
        path: `sysadmin/instansi-partner?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[nama_instansi]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          if(this.dataPartner.length == 0){
            data.data.forEach(item => {
              let dataInstansi = {
                id:null,
                community_development_event_id:null,
                klien_id:item.klien_id,
                instansi_partner_id:item.id,
                nama_instansi:item.nama_instansi,
                email:item.email,
                alamat:item.alamat,
              };
              this.dataPartner.push(dataInstansi);
              this.isRunning = false;
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
          this.isRunning = false;
        });
    },
    updateData() {
      this.loadingSave = true;
      let data = {
        body: {
          id: this.$route.params.idActivities,
          nama: this.dataSet.nama,
          tanggal_pelaksanaan: this.dataSet.tanggal_pelaksanaan,
          metode: this.dataSet.metode,
          tujuan: this.dataSet.tujuan,
          lokasi: this.dataSet.lokasi,
          instansi_partner: JSON.stringify(this.field.partner),
        },
        path: `sysadmin/admin/community-development/event/update`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          if (data.status == "success") {
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });

      this.loadingSave = false;
    },
    remove(idx) {
      this.field.idx_deleted = idx;
      if(this.field.partner[idx].id){
        this.inventory = {
            data: this.field.partner[idx],
            path: `sysadmin/admin/community-development/event/delete-instansi`,
            deleteType: "Instansi Event",
          };
          this.d_type = "delete";
          this.d_title = "Hapus Instansi Event";
          this.d_small = true;
          this.line = true;
      }else{
        this.inventory = {
            data: this.field.partner[idx],
            path: ``,
            deleteType: "Instansi Event",
        };
        this.d_type = "delete";
        this.d_title = "Hapus Instansi Event";
        this.d_small = true;
        this.line = true;
      }
    },
    addData(item){
      let body = {
        community_development_event_id:this.$route.params.idActivities,
        instansi_partner_id:item.instansi_partner_id,
        klien_id:item.klien_id,
      };
      this.inventory = {
        body: body,
        path: `sysadmin/admin/community-development/event/add-instansi`,
        addType: "Instansi Event",
      };
      this.d_type = "add_function";
      this.d_title = "Tambah Instansi Event";
      this.d_small = true;
      this.line = true;
    }
  },
};
</script>

<style></style>
